import { call, takeEvery, put } from 'redux-saga/effects';
import * as authActions from '../actions';
import { getStatByLogin } from '../dash/actions'
import { authRequest } from '../../requests/authRequest';
import { AppLocalStorage, RestorePayload } from '../../constants/payloads'

function* signOutSaga(action) {
    try {
        localStorage.setItem(AppLocalStorage, JSON.stringify({ JLKSession: {} }));
        yield put(authActions.signOutSuccess());

    } catch (error) {
        console.log('signOutSaga Saga Error response', error);

    }
}


function* signInSaga({ payload }) {
    try {
        const response = yield call(authRequest, payload);
        if (response.status === 200) {
            const { from_data_date, login, to_data_date } = payload;
            const { key } = response.data;
            yield put(authActions.signInSuccess({ key, login }));
            localStorage.setItem(AppLocalStorage, JSON.stringify({ JLKSession: { ...RestorePayload, from_data_date, key, login, to_data_date } }));
            yield put(getStatByLogin({
                ...response.data, menu: [...response.data.menu,
                ]
            }));

        } else {
            throw response;
        }
    } catch (error) {
        console.log('authSaga Saga Error response', error);
        localStorage.setItem(AppLocalStorage, JSON.stringify({ JLKSession: {} }))
        yield put(authActions.signInError(error.response.data));
    }
}

export default function* authWatcher() {
    yield takeEvery(authActions.signIn.toString(), signInSaga);
    yield takeEvery(authActions.signOut.toString(), signOutSaga);
}
